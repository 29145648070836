import React from 'react';
import styled from 'styled-components';
import { useStoreState } from 'easy-peasy';
import swal from 'sweetalert';

import { useCancellTableBooking, useTableBookingsDetails } from './helpers';

const TableItem = styled.div`
  border: 1px solid #e2e2e2;
  font-size: 12px;
  color: black;
  padding: 10px;
  text-align: center;
`;

const TableItems = ({ ele, placeId, history }) => {
  const { userId: adminUserId } = useStoreState(state => state.auth);

  const [
    ,
    tableBookingDetails,
    refetchTableBookingsDetails,
  ] = useTableBookingsDetails({
    adminUserId,
    tableBookingId: ele.table_booking_id,
    placeId,
  });

  const [
    isCancellingTableBooking,
    cancellTableBooking,
  ] = useCancellTableBooking({
    adminUserId,
    tableBookingId: ele.table_booking_id,
  });

  return (
    <>
      <TableItem style={{ fontSize: '10px' }}>
        <div className="is-flex-direction-column">
          <div className="has-text-left">{ele.booking_time}</div>
          <div className="has-text-left">{ele.booking_date}</div>
        </div>
      </TableItem>
      <TableItem style={{ fontSize: '10px' }}>
        <div className="is-flex-direction-column">
          <div>{ele.guest_count}</div>
        </div>
      </TableItem>
      <TableItem
        style={{ fontSize: '10px', cursor: 'pointer' }}
        onClick={() =>
          history.push(`/add-modify-booking/${placeId}/${ele.table_booking_id}`)
        }
      >
        <div className="is-flex-direction-column">
          <div className="has-text-left">
            {ele.first_name} {ele.last_name}
          </div>
          {tableBookingDetails?.restaurant_notes && (
            <div className="has-text-left" style={{ color: '#505050' }}>
              {tableBookingDetails.restaurant_notes}
            </div>
          )}
          {tableBookingDetails?.guest_notes && (
            <div className="has-text-left" style={{ color: '#EE2A7B' }}>
              {tableBookingDetails.guest_notes}
            </div>
          )}
        </div>
      </TableItem>
      <TableItem style={{ fontSize: '10px' }}>
        <div className="is-flex-direction-column">
          <div className="has-text-left">
            {tableBookingDetails?.table_number}
          </div>
        </div>
      </TableItem>
      <TableItem style={{ fontSize: '10px' }}>
        <div className="is-flex-direction-column">
          <div className="has-text-left">{ele.contact_phone}</div>
          <div className="has-text-left" style={{ color: '#FF9900' }}>
            {tableBookingDetails?.guest_status}
          </div>
        </div>
      </TableItem>
      <TableItem style={{ fontSize: '10px' }}>
        <div className="is-flex-direction-column">
          <div>
            <span
              style={{ cursor: 'pointer' }}
              onClick={() => {
                history.push(
                  `/add-modify-booking/${ele.table_booking_id}/${placeId}`,
                );
              }}
            >
              <i
                className="fas fa-edit"
                style={{ color: '#737373', paddingLeft: '12px' }}
              />
            </span>

            {isCancellingTableBooking && (
              <span>
                <i
                  className="fa fa-spinner"
                  style={{ color: '#737373', paddingLeft: '12px' }}
                />
              </span>
            )}

            {tableBookingDetails?.booking_status !== 'CANCELLED' &&
              !isCancellingTableBooking && (
                <span
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    swal({
                      text: 'Would you like to cancel this table booking?',
                      buttons: ['Cancel', 'OK'],
                    }).then(response => {
                      if (response) {
                        cancellTableBooking().then(refresh => {
                          if (refresh) {
                            refetchTableBookingsDetails();
                          }
                        });
                      } else {
                        // pass
                      }
                    });
                  }}
                >
                  <i
                    className="far fa-trash-alt"
                    style={{ color: '#737373', paddingLeft: '12px' }}
                  />
                </span>
              )}
          </div>
          <div style={{ color: '#505050' }}>
            {tableBookingDetails?.booking_status}
          </div>
        </div>
      </TableItem>
    </>
  );
};

export default TableItems;
